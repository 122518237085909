body {
  background-color: black;

  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
  
}

*::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

header {
  background-color: black;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  position: sticky;
  top: 0px;
}

.headerp {
  font-size: 25px;
}

.headCombo {
  cursor: pointer;
  font-size: 17px;
}

.headCombo:hover {
  color: gold;
}

.headChat {
  display: none;
}

.headChat:hover {
  color: gold;
}

.headBMenu {
  display: none;
}

.introduction {
  background-color: rgb(123, 19, 221);
  width: 95%;
  border-radius: 10px;
}

.text1 {
  font-size: 35px;
  font-family: 'Times New Roman', Times, serif;
  font-style: italic;
}

.parentAni {
  width: fit-content;
}

.animateText {
  color: gold;
  display: flex;
  margin: 0;
  margin-left: 20px;
  padding: 0;
}

.animateText::before {
  content: attr(data-text);
  width: 0;
  height: 50px;
  border-right: 2px solid gold;
  overflow: hidden;
  animation: animate 3s linear infinite, words 12s infinite;
}

.letstalk {
  background-color: gold;
  color: black;
  cursor: pointer;
  border: none;
  font-size: 17px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.letstalk:hover {
  background-color: black;
  color: white;
}

.introimg {
  border: 7px solid red;
  border-radius: 50%;
  aspect-ratio: 1/1;
  width: fit-content;
  height: fit-content;

  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.introimg img {
  aspect-ratio: 1/1;
  width: 200px;
}

.pop-up {
  background-color: black;
  color: white;
  width: 250px;
  height: 250px;
  /* display: flex;
  justify-content: space-evenly;
  align-items: center; */
  border-radius: 10px;
  position: fixed;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.1);
  transition: all 0.4s;
  visibility: hidden;
}

.pophead {
  width: 100px;
  position: relative;
  top: -50px;
  border-radius: 50%;
  margin-bottom: -40px;
}

.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  font-weight: 700;
  border-radius: 10px;
  box-shadow: 0.5px 0.5px 25px goldenrod;
}

.pop-ups {
  visibility: visible;
  transform: translate(-50%, -50%) scale(1);
}

.blurback {
  filter: blur(1.5px);
}

.skillComp, .servComp {
  width: 95%;
  height: 95%;
  max-width: 500px;
  max-height: 600px;
  background-color: black;
  border: 0.1px solid gold;
  border-radius: 15px;
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%) scale(0.1);
  transition: transform 0.4s;
  visibility: hidden;
}

.skillComps, .servComps {
  visibility: visible;
  transform: translate(-50%, -50%) scale(1);
}

.skillComp h4, .servComp h4 {
  background-color: goldenrod;
  border-radius: 10px;
  width: 150px;
  height: 38px;
  box-shadow: 1.5px 1.5px gold;
}

.servComp p {
  color: white;
  border: 0.5px solid goldenrod;
  border-radius: 5px;
  padding: 7px;
  padding-left: 20px;
  margin: 7px;
  margin-left: 20px;
  margin-right: 20px;
}

.skillComp img, .servComp img {
  width: 35px;
  background-color: gold;
  border-radius: 50%;
  border: 1px solid gold;
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}

.awh {
  /* background-color: goldenrod; */
  color: goldenrod;
  border: 1px solid goldenrod;
  border-radius: 5px;
  padding: 9px;
  max-width: 300px;
}

.projects > p {
  color: goldenrod;
  text-align: left;
  margin-left: 8px;
  font-weight: bold;
}

.proj1,
.proj2,
.proj3 {
  /* max-width: 375px; */
  border: 1px solid goldenrod;
  border-radius: 2px;

  padding: 20px;
}

.mainProjectItem {
  min-width: 260px;
  max-width: 350px;
}

.image {
  width: 100%;
  max-width: 350px;
  aspect-ratio: 16/9;
}

.proj p {
  color: white;
  width: 100%;
  margin-top: 12px;
  font-weight: 500;
}

.visitSiteA {
  border: none;
  outline: none;
  border-radius: 5px;
  margin-top: 5px;
  color: black;
  background-color: goldenrod;
  width: 80%;
  min-width: 85.18px;
  height: 35px;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.githubA {
  border: none;
  outline: none;
  border-radius: 5px;
  margin-top: 5px;
  margin-left: 5px;
  color: black;
  background-color: goldenrod;
  width: 43px;
  height: 35px;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.proj a:hover {
  background-color: darkgoldenrod;
  color: black;
}

.skillSect .skillHeadBtns {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 1rem;
  margin-bottom: 10px;
  overflow-x: auto;
  overscroll-behavior-inline: contain;
  scroll-snap-type: inline mandatory;
  scroll-padding-inline: 1rem;
}

.skillSect .skillHeadBtns > div {
  background-color: black;
  color: white;
  font-weight: bolder;
  font-style: italic;
  margin-inline: 0.5rem;
  padding: 0.3rem;
  padding-inline: 2rem;
  border-radius: 5px;
  border: 1px solid darkgoldenrod;
  cursor: pointer;

  scroll-snap-align: start;
}

.skillSect .skillHeadBtns > div:hover {
  background-color: darkgoldenrod;
  color: black;
}

.skillItems {
  display: flex;
  justify-content: space-evenly;
}

.skillItems img {
  width: 100%;
}



.cardScrollBar::-webkit-scrollbar {
  display: block;
  height: 4px;
}

.cardScrollBar::-webkit-scrollbar-track {
  background-color: darkslategrey;
  border-radius: 5px;
  cursor: pointer;
}

.cardScrollBar::-webkit-scrollbar-thumb {
  background-color: darkgoldenrod;
  border-radius: 5px;
}

.myCardCon {
  display: grid;
  gap: 1rem;
  grid-auto-flow: column;
  grid-auto-columns: 21%;

  overflow-x: auto;
  overscroll-behavior-inline: contain;
}

.snapCard {
  scroll-snap-type: inline mandatory;
  scroll-padding-inline: 1rem;
}

.snapCard > * {
  scroll-snap-align: start;
}

footer {
  height: 100px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px !important;
}

/* Media Quries */

@media screen and (min-width: 500px) {
  /* header {
      display: flex;
      justify-content: space-between;
      padding-left: 20px;
      padding-right: 20px;
  } */


  .headChat {
      display: block;
      cursor: pointer;
      font-size: 17px;
  }

  /* .pop-up {
      width: 200px;
  } */
}

@media screen and (min-width: 600px) {
  .introFlex {
      display: flex;
      padding-top: 50px;
      justify-content: space-evenly;
      max-width: 1000px;
  }
}

@media screen and (min-width: 700px) {
  .headBMenu {
    display: flex;
  }

  .menuBar {
    display: none;
  }
  .menuItems {
    visibility: hidden;
    position: fixed;
    top: 68px;
    right: 150px;
  }
}

@media screen and (max-width: 768px) {
  .mainProjectItem {
    min-width: 260px;
    max-width: 300px;
  }
}

@media screen and (max-width: 700px) {
  .menuBar img {
    width: 35px;
    /* border: 0.1px solid gold;
    background-color: gold; */
    border-radius: 50%;
    cursor: pointer;
  }

  .menuItems {
    background-color: goldenrod;
    width: 200px;
    height: 100%;
    position: fixed;
    top: 68px;
    right: -100%;
    transition: all 0.4s;
  }

  .menuItemss {
    right: 0px;
  }

  .menuItems button {
    display: block;
    background-color: black;
    color: white;
    border: none;
    border-radius: 5px;
    width: 130px;
    margin: 9px;
    padding: 5px;
    box-shadow: 3px 3px rgb(61, 48, 48);
  }
}

@media screen and (max-width: 500px) {
  .letstalk {
    padding-bottom: 6px;
  }
}

@media screen and (max-width: 400px) {
  .myCardCon {
    grid-auto-columns: 80%;
  }
}

@media screen and (min-width: 400px) {
  .myCardCon {
    grid-auto-columns: 60%;
  }
}

@media screen and (min-width: 500px) {
  .myCardCon {
    grid-auto-columns: 42%;
  }
}

@media screen and (min-width: 700px) {
  .myCardCon {
    grid-auto-columns: 36%;
  }
}

@media screen and (min-width: 900px) {
  .myCardCon {
    grid-auto-columns: 29%;
  }
}

@media screen and (min-width: 1000px) {
  .myCardCon {
    grid-auto-columns: 23%;
  }
}

@media screen and (min-width: 1100px) {
  .myCardCon {
    grid-auto-columns: 21%;
  }
}

@media screen and (max-width: 630px) {
  .skillSect .skillHeadBtns {
    padding-inline-start: 20rem;
  }
}

@keyframes animate {
  0%, 10%, 100% {
    width: 0;
  }
  70%, 90% {
    width: 100%;
  }
}

@keyframes words {
  0%, 25% {
      content: "Prince !";
  }
  26%, 50% {
      content: "Coder";
  }
  51%, 75% {
      content: "Programmer";
  }
  76%, 100% {
      content: "Developer";
  }
}